import { GetStaticPaths, GetStaticProps } from 'next'
import {
  ContentPage as Page,
  ContentPageProps,
  generateStaticContentPageProps,
} from '../components/ContentPage/ContentPage'
import { createContentfulClient, getPages } from '../lib/contentful'
import { SSR_LOCALE } from '../config/locale'

export default Page

type ContentPagePaths = {
  slug: string[]
}

export const getStaticProps: GetStaticProps<ContentPageProps, ContentPagePaths> = async context => {
  const { slug } = context.params

  const completeSlug = slug.join('/')

  return generateStaticContentPageProps(completeSlug)
}

export const getStaticPaths: GetStaticPaths<ContentPagePaths> = async () => {
  const contentfulClient = createContentfulClient()
  const pages = await getPages(contentfulClient, 1)

  const paths = pages
    // Exclude home page
    .filter(page => page.fields.slug[SSR_LOCALE] !== '/')
    .map(page => {
      // Safely split into parts
      const slug = page.fields.slug[SSR_LOCALE].split('/').filter(path => path)
      return {
        params: { slug },
      }
    })

  return {
    paths,
    fallback: 'blocking',
  }
}
